<template>
  <div class="login">
    <video src="@/assets/back.mp4" ref="video" style="width: 100vw" muted="muted" loop></video>
    <!-- 账号密码登录 -->
    <div class="num-login">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" v-show="loginType == 1">
        <div class="title">
          <!-- <h3>深圳职业技术大学</h3>
                    <h3>食品安全指挥管理系统</h3> -->
          <img src="@/assets/login-Title.png" alt="" style="width: 1.6979rem; height: 0.5208rem" />
        </div>
        <div class="input-label">账号</div>
        <el-form-item style="width: 1.8125rem" prop="username">
          <el-input v-model="loginForm.username" type="text" placeholder="账号"> </el-input>
        </el-form-item>
        <div class="input-label">密码</div>
        <el-form-item prop="password" style="width: 1.8125rem">
          <el-input v-model="loginForm.password" type="password" :readonly="readonly" :show-password="true" @focus="readonly = false" placeholder="密码" @keyup.enter.native="handleLogin"> </el-input>
        </el-form-item>
        <el-form-item style="width: 1.8125rem">
          <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 0.0469rem">
            <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
            <!-- <el-link
                            @click="forgetPasswordDialog()"
                            :underline="false"
                            class="forget-password"
                            >忘记密码？</el-link
                        > -->
          </div>
        </el-form-item>
        <el-form-item style="width: 1.8125rem">
          <el-button :loading="loading" size="medium" type="primary" style="width: 100%; height: 0.2292rem; margin-top: 0.1023rem" @click.native.prevent="handleLogin">
            <span class="login-btn" v-if="!loading">登 录</span>
            <span class="login-btn" v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
        <!--验证码弹窗-->
        <el-dialog title="验证登录" :visible.sync="dialogVisible" width="3.3125rem" :close-on-click-modal="false">
          <div class="code-tip">由于您登录的账号需要权限确认，请输入系统管理员收到的短信验证码</div>
          <el-input v-model="mobile" style="width: 100%; margin-bottom: 0.1042rem" placeholder="请输入手机号码"></el-input>
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 0.1042rem">
            <el-input v-model="codeForm.code" style="width: 1.0417rem" placeholder="请输入短信验证码"></el-input>
            <el-button :disabled="codeCD > 0" type="text" @click="getNoteCode" style="width: 0.5833rem; color: #fff">{{ codeCD > 0 ? codeCD + "s" : "发送验证码" }}</el-button>
          </div>
          <el-button type="primary" style="width: 100%" @click="noteCodeLogin">登 录</el-button>
        </el-dialog>
      </el-form>
    </div>

    <!--忘记密码, 重置密码弹框-->
    <el-dialog :title="passwordControl.type == 2 ? '找回密码' : '重置密码'" :visible.sync="passwordControl.dialogVisible" :close-on-click-modal="false" width="3.0885rem !important">
      <div class="dialog-top"></div>
      <div class="dialog-bottom"></div>
      <el-form label-position="top" ref="passwordForm" :model="passwordControl.passwordForm" :rules="passwordControl.rules" label-width=".4167rem">
        <el-form-item label="用户账号" prop="account">
          <el-input v-model="passwordControl.passwordForm.account" placeholder="请输入用户账号" :disabled="passwordControl.type == 1" autocomplete="off" disableautocomplete style="width: 2.724rem"></el-input>
          <el-input style="position: fixed; bottom: -52.0781rem"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input style="width: 2.724rem" v-if="passwordControl.type == 2" v-model="passwordControl.passwordForm.phone" placeholder="请输入账号绑定的手机号" :disabled="passwordControl.type == 1" autocomplete="off" disableautocomplete></el-input>
          <el-input v-else style="width: 2.724rem" placeholder="请输入账号绑定的手机号" :disabled="passwordControl.type == 1" :value="passwordControl.passwordForm.hidePhone || passwordControl.passwordForm.phone" autocomplete="off" disableautocomplete></el-input>
          <el-input style="position: fixed; bottom: -52.0781rem"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="passwordControl.passwordForm.code" placeholder="请输入短信验证码" style="width: 2.2031rem" autocomplete="off" disableautocomplete></el-input>
          <el-input style="position: fixed; bottom: -52.0781rem"></el-input>
          <el-button style="width: 0.4167rem; color: #fff" type="text" :disabled="!!passwordControl.codeCd" @click="forgetPasswordCode()">{{ passwordControl.codeCd ? passwordControl.codeCd + "s" : "获取验证码" }}</el-button>
          <div class="code-tips">
            收不到验证码<el-tooltip placement="left">
              <div slot="content" style="line-height: 0.1042rem">
                您好，若手机已停用，可以尝试联系管理员重置手机号。
                <br />
                <br />
                若仍无法解决，请按如下方法排查；
                <br />
                1. 手机安全软件是否拦截；
                <br />
                2. 所处地区信号不好或接受短信的时间点手机处于关机状态；
                <br />
                3. 双卡双待手机，AB卡槽是否正确安放（可尝试更换AB卡位置重试）；
                <br />
                4. 长时间未关机，可尝试关机重启后重新尝试；
                <br />
                5. 手机欠费/停机
              </div>
              <i class="el-icon-question" style="width: 0.0625rem; height: 0.0625rem"></i>
            </el-tooltip>
          </div>
        </el-form-item>
        <input type="password" style="display: none" />
        <el-form-item label="新密码" prop="password" style="margin-bottom: 0.1823rem">
          <el-input type="password" style="position: fixed; bottom: -52.0781rem"></el-input>
          <el-input style="width: 2.724rem" v-model="passwordControl.passwordForm.password" placeholder="请输入密码，8-25位字符，需包含数字、大小写字母" :show-password="true" maxlength="25" autocomplete="off" disableautocomplete></el-input>
          <div class="password-tips">
            密码要求<el-tooltip placement="left">
              <div slot="content" style="line-height: 0.1042rem">
                密码要求
                <br />
                <br />
                密码长度在8~25位
                <br />
                必须包含至少1位小写字母（a-z）和大写字母（A-Z）及数字
                <br />
                密码中不得包含用户名，连续数字串（如12345）
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item label="确认密码" prop="passwordAgain">
          <el-input type="password" style="position: fixed; bottom: -52.0781rem"></el-input>
          <el-input v-model="passwordControl.passwordForm.passwordAgain" placeholder="请再次输入密码" :show-password="true" maxlength="25" style="width: 523px" autocomplete="off" disableautocomplete></el-input>
        </el-form-item>
        <el-form-item style="text-align: right; margin-bottom: 0rem !important">
          <div class="btns">
            <div @click="forgetPasswordCancel()" class="btn">取消</div>
            <div @click="forgetPasswordComfirm()" class="btn confirm">确定</div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getCodeImg, smsSend, getInfo, firstModifyPassword, systemLoginCheckCode, systemLoginSendCode, SystemLoginAdmin, getRsaPublicKey, captchaMechanismTrigger, scanUuid, scanStatus, wxGetToken } from "@/api/login";
import { setToken } from "@/utils/auth";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import aes from "@/utils/aes";
import { rsaEncrypt } from "@/utils/rsa";

import { getUser } from "@/api/permission/user";
// import VueQr from "vue-qr";
import dayjs from "dayjs";

export default {
  name: "Login",

  data() {
    // 重置密码 密码格式校验
    var validatePasswordFormat = (rule, value, callback) => {
      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*).{8,25}/g;
      let objExp = new RegExp(reg);

      if (!objExp.test(value)) {
        callback(new Error("请输入密码，8-25位字符，需包含数字、大小写字母"));
        return;
      }

      let reg1 = /(0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)){4,}./g;
      let objExp1 = new RegExp(reg1);
      if (objExp1.test(value)) {
        callback(new Error("不得出现连续数字"));
        return;
      }

      let reg2 = /(9(?=8)|8(?=7)|7(?=6)|6(?=5)|5(?=4)|4(?=3)|3(?=2)|2(?=1)|1(?=0)){4,}./g;
      let objExp2 = new RegExp(reg2);
      if (objExp2.test(value)) {
        callback(new Error("不得出现连续数字"));
        return;
      }
      let reg3 = /(\d)\1{4,}/g;
      let objExp3 = new RegExp(reg3);
      if (objExp3.test(value)) {
        callback(new Error("不得出现重复数字"));
        return;
      }
      callback();
    };
    // 重置密码重新输入密码校验
    var validatePassword = (rule, value, callback) => {
      if (value != this.passwordControl.passwordForm.password) {
        callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    };
    return {
      title: "深圳职业技术大学智能设备监管平台",
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: "blur",
            message: "用户名不能为空",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: "密码不能为空",
          },
          {
            min: 8,
            max: 25,
            message: "密码长度最小8位，最大25位",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            trigger: "blur",
            message: "验证码不能为空",
          },
          {
            min: 4,
            max: 4,
            message: "请输入4位验证码",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      redirect: undefined,
      dialogVisible: false,
      codeForm: {
        code: "",
      },
      codeCD: 0,
      mobile: "",
      readonly: false,
      timer: null,

      // 找回密码, 忘记密码
      passwordControl: {
        type: 1, // 1 找回密码, 2 重置密码
        dialogVisible: false,
        rules: {
          account: [
            {
              required: true,
              message: "请输入用户账号",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "请输入正确的手机号",
              trigger: "blur",
            },
            {
              min: 11,
              max: 11,
              message: "长度11位",
              trigger: "blur",
            },
          ],
          code: [
            {
              required: true,
              message: "请输入正确的验证码",
              trigger: "blur",
            },
          ],
          password: [
            {
              required: true,
              message: "请输入密码",
              trigger: "blur",
            },
            {
              min: 8,
              max: 25,
              message: "请输入密码，8-25位字符，需包含数字、大小写字母",
              trigger: "blur",
            },
            {
              validator: validatePasswordFormat,
              trigger: "blur",
            },
          ],
          passwordAgain: [
            {
              required: true,
              message: "请输入新密码",
              trigger: "blur",
            },
            {
              min: 8,
              max: 25,
              message: "请输入密码，8-25位字符，需包含数字、大小写字母",
              trigger: "blur",
            },
            { validator: validatePassword, trigger: "blur" },
          ],
        },
        passwordForm: {
          account: "",
          phone: "",
          code: "",
          password: "",
          passwordAgain: "",
        },
        codeCd: 0, // 发送验证码60秒
        codeNum: 0, // 发送验证码次数
        timer: null, // 计时器
        rsaPublicKey: "",
        aesKey: "",
        captchaTicket: undefined,
        captchaRandstr: undefined,
      },

      loginType: 1, // 1 账号密码登录 2 二维码登录
      qrcodeText: "",
      qrcode: "",
      qrcodePage: 1,
      now: dayjs().valueOf(),
    };
  },
  computed: {
    //   判断是否是谷歌浏览器
    isChrome() {
      return navigator.userAgent.indexOf("Chrome") >= 0;
    },
  },
  created() {
    this.getCookie();
  },
  mounted() {
    const video = this.$refs.video;
    video.play();
  },
  methods: {
    // 获取角色权限
    getRoles(userId) {
      getUser(userId).then((response) => {
        let dataScope = "5";
        if (response.data) {
          dataScope = response.data.user.roles[0].dataScope;
        }
        Cookies.set("dataScope", dataScope, {
          expires: 30,
        });
      });
    },

    //获取验证码
    getCode() {
      getCodeImg().then((res) => {
        if (res.code === 200) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.loginForm.uuid = res.data.uuid;
        } else {
          this.msgError("获取验证码失败");
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, {
              expires: 30,
            });
            Cookies.set("password", encrypt(this.loginForm.password), {
              expires: 30,
            });
            Cookies.set("rememberMe", this.loginForm.rememberMe, {
              expires: 30,
            });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }

          captchaMechanismTrigger(this.loginForm.username).then((res) => {
            if (res.data) {
              this.varify();
            } else {
              this.login();
            }
          });
        }
      });
    },
    loginCommon() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.passwordForm.clearValidate();
        });
        this.passwordControl.dialogVisible = true;
      }, 1000);
    },
    login() {
      this.loading = true;
      /**
       * 登录前密码加密处理
       */
      getRsaPublicKey().then((res) => {
        if (res.code === 200) {
          this.rsaPublicKey = res.data;
          // 生成aes密钥
          this.aesKey = aes.generateAesKey(16);
          // 对密码进行aes加密
          let password = aes.aesEncrypt(this.loginForm.password, this.aesKey);
          // 对aesKey进行公钥加密
          let decryptAesKey = rsaEncrypt(this.aesKey, this.rsaPublicKey);
          // 重构登录参数
          let params = {
            username: this.loginForm.username,
            password: password,
            code: this.loginForm.code,
            uuid: this.loginForm.uuid,
            unionid: this.loginForm.unionid,
            openid: this.loginForm.openid,
            aesKey: decryptAesKey,
            originAesKey: this.aesKey,
            ticket: this.captchaTicket,
            randStr: this.captchaRandstr,
          };
          this.$store
            .dispatch("Login", params)
            .then((loginInfo) => {
              // 登录接口返回code1,和手机号, 需要重置密码
              if (loginInfo.data.code == 1) {
                this.passwordControl.type = 1; // 重置密码
                this.passwordControl.passwordForm.account = params.username;
                this.passwordControl.passwordForm.phone = loginInfo.data.phoneNo;
                this.passwordControl.passwordForm.hidePhone = this.hidePhone(loginInfo.data.phoneNo);
                this.msgSuccess("保障登录安全，请先重置密码");
                this.loginCommon();
                return;
              }
              // 返回 res.data==00, 超管需要验证码
              if (loginInfo.data == "00") {
                this.dialogVisible = true;
                return;
              }

              getInfo().then((res1) => {
                if (res1.code === 200) {
                  res1.data.user.permissions = res1.data.permissions;
                  this.storageUserInfo(res1.data.user);
                  this.getRoles(res1.data.user.userId);
                  Cookies.set("userName", res1.data.user.userName);
                  Cookies.set("avatar", res1.data.user.avatar);
                  Cookies.set("phonenumber", res1.data.user.phonenumber);
                  setTimeout(() => {
                    this.$router.replace("/safe");
                  }, 1000);
                } else {
                  this.msgError(res1.msg);
                }
              });
              this.msgSuccess("登录成功");
            })
            .catch((result) => {
              if (result.data == "00") {
                this.dialogVisible = true;
              } else {
                this.msgError(result.msg);
                // 微信绑定登录报错
                if (this.loginForm.unionid) {
                  setTimeout(() => {
                    this.$router.replace("/login");
                  }, 1000);
                }
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.msgError("公钥获取失败，请稍后再试");
        }
      });
    },
    //获取手机号
    getNoteCode() {
      if (!this.mobile) return this.msgError("请输入手机号码");
      if (!/^1\d{10}$/.test(this.mobile)) return this.msgError("请输入正确的手机号码");
      smsSend({
        mobile: this.mobile,
      }).then((res) => {
        if (res.code === 200) {
          this.codeCD = 120;
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            if (this.codeCD > 0) {
              this.codeCD--;
            } else {
              clearInterval(this.timer);
            }
          }, 1000);
          this.codeForm.createTime = res.data;
          this.msgSuccess(res.msg);
        } else {
          this.msgError(res.msg);
        }
      });
    },
    //发送验证码
    noteCodeLogin() {
      if (!this.codeForm.createTime) return this.msgError("请发送验证码");
      if (!this.codeForm.code) return this.msgError("请输入验证码");
      getRsaPublicKey().then((res) => {
        if (res.code === 200) {
          this.rsaPublicKey = res.data;
          // 生成aes密钥
          this.aesKey = aes.generateAesKey(16);
          // 对密码进行aes加密
          let password = aes.aesEncrypt(this.loginForm.password, this.aesKey);
          // 对aesKey进行公钥加密
          let decryptAesKey = rsaEncrypt(this.aesKey, this.rsaPublicKey);
          let params = {
            userName: this.loginForm.username,
            password: password,
            smsCode: this.codeForm.code,
            secretKey: decryptAesKey,
          };
          SystemLoginAdmin(params).then((result) => {
            if (result.code === 200) {
              this.$message({
                message: "登录成功",
                type: "success",
              });
              // token 解码
              let token = aes.aesDecrypt(result.data.token, this.aesKey);
              setToken(token);
              this.$store.commit("SET_TOKEN", token);
              this.$router.push("/safe");
            } else {
              this.msgError(result.msg);
              this.loading = false;
            }
          });
        } else {
          this.msgError(res.msg);
        }
      });
    },

    // 忘记密码弹框
    forgetPasswordDialog() {
      this.forgetPasswordReset();
      this.passwordControl.type = 2;
      this.passwordControl.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.passwordForm.clearValidate();
      });
    },
    // 忘记密码重置数据
    forgetPasswordReset() {
      this.passwordControl.passwordForm = {
        account: "",
        phone: "",
        code: "",
        password: "",
        passwordAgain: "",
      };
    },
    // 忘记密码--发送验证码
    forgetPasswordCode() {
      if (!this.passwordControl.passwordForm.account) {
        return this.msgError("请输入账号");
      }
      if (!this.passwordControl.passwordForm.phone) {
        return this.msgError("请输入手机号");
      }
      let validateNum = 0;
      this.$refs.passwordForm.validateField(["account", "phone"], (validate) => {
        if (!validate) {
          validateNum++;
          // 两种都通过
          if (validateNum == 2) {
            getRsaPublicKey().then((res) => {
              if (res.code === 200) {
                this.rsaPublicKey = res.data;
                // 生成aes密钥
                this.aesKey = aes.generateAesKey(16);
                // 对密码进行aes加密
                let phone = aes.aesEncrypt(this.passwordControl.passwordForm.phone, this.aesKey);
                // 对aesKey进行公钥加密
                let decryptAesKey = rsaEncrypt(this.aesKey, this.rsaPublicKey);
                // 发送验证码开始计时
                this.passwordControl.codeCd = 60;
                this.passwordControl.timer = setInterval(() => {
                  if (this.passwordControl.codeCd > 0) {
                    this.passwordControl.codeCd--;
                  } else {
                    clearInterval(this.passwordControl.timer);
                  }
                }, 1000);

                this.passwordControl.codeNum++;

                let params = {
                  phoneNo: phone,
                  userName: this.passwordControl.passwordForm.account,
                  secretKey: decryptAesKey,
                  sendType: this.passwordControl.type == 2 ? "2" : "1",
                };

                systemLoginSendCode(params).then((res1) => {
                  if (res1.code === 200) {
                    this.msgSuccess(res1.msg);
                  } else {
                    this.passwordControl.codeCd = 0;
                    clearInterval(this.passwordControl.timer);
                    this.msgError(res1.msg);
                  }
                });
              } else {
                this.msgError(res.msg);
              }
            });
          }
        }
      });
    },
    // 忘记密码确定
    forgetPasswordComfirm() {
      this.$refs.passwordForm.validate((validate) => {
        if (validate) {
          // 如果没有点击发送验证码按钮
          if (!this.passwordControl.codeNum) {
            return this.msgError("请先发送验证码");
          }
          getRsaPublicKey().then((res) => {
            if (res.code === 200) {
              this.rsaPublicKey = res.data;
              // 生成aes密钥
              this.aesKey = aes.generateAesKey(16);
              // 对密码进行aes加密
              let phone = aes.aesEncrypt(this.passwordControl.passwordForm.phone, this.aesKey);
              let code = aes.aesEncrypt(this.passwordControl.passwordForm.code, this.aesKey);
              // 对aesKey进行公钥加密
              let decryptAesKey = rsaEncrypt(this.aesKey, this.rsaPublicKey);
              systemLoginCheckCode({
                code: code,
                phoneNo: phone,
                secretKey: decryptAesKey,
                checkType: this.passwordControl.type == 2 ? "2" : "1",
              }).then((res1) => {
                if (res1.data.code == 1) {
                  return this.msgError(res1.data.msg);
                }

                // 生成aes密钥
                this.aesKey = aes.generateAesKey(16);
                // 对密码进行aes加密
                phone = aes.aesEncrypt(this.passwordControl.passwordForm.phone, this.aesKey);
                code = aes.aesEncrypt(this.passwordControl.passwordForm.code, this.aesKey);
                let password = aes.aesEncrypt(this.passwordControl.passwordForm.password, this.aesKey);
                let confirmPassword = aes.aesEncrypt(this.passwordControl.passwordForm.passwordAgain, this.aesKey);
                // 对aesKey进行公钥加密
                decryptAesKey = rsaEncrypt(this.aesKey, this.rsaPublicKey);

                let params = {
                  type: this.passwordControl.type,
                  userName: this.passwordControl.passwordForm.account,
                  password: password,
                  confirmPassword: confirmPassword,
                  phone: phone,
                  smsCode: code,
                  secretKey: decryptAesKey,
                };
                // 修改密码
                firstModifyPassword(params).then((res2) => {
                  if (res2.code != 200) {
                    return this.msgError(res2.msg);
                  } else {
                    this.msgSuccess(res2.msg);
                    if (res2.data && res2.data.token) {
                      // token 解密
                      let token = aes.aesDecrypt(res2.data.token, this.aesKey);
                      setToken(token);
                      this.$store.commit("SET_TOKEN", token);
                      getInfo().then((info) => {
                        this.storageUserInfo(info.data.user);
                        this.getRoles(info.data.user.userId);
                        Cookies.set("userName", info.data.user.userName);
                        Cookies.set("avatar", info.data.user.avatar);
                        Cookies.set("phonenumber", info.data.user.phonenumber);
                        this.$router.replace("/safe");
                      });
                    }
                    this.passwordControl.dialogVisible = false;
                    this.$router.replace("/safe");
                  }
                });
              });
            } else {
              this.msgError(res.msg);
            }
          });
        }
      });
    },
    // 忘记密码取消
    forgetPasswordCancel() {
      this.passwordControl.dialogVisible = false;
    },

    // 手机号码隐藏四位
    hidePhone(phone) {
      if (phone) {
        phone += "";
        if (phone.length >= 11) {
          let temp = phone.split("");
          temp[3] = "*";
          temp[4] = "*";
          temp[5] = "*";
          temp[6] = "*";
          return temp.join("");
        }
      }
      return phone;
    },

    varify() {
      this.newTencentCaptcha(this.tencentCaptchaCallback);
    },

    tencentCaptchaCallback(captchaRes) {
      if (captchaRes.ret === 0) {
        this.captchaTicket = captchaRes.ticket;
        this.captchaRandstr = captchaRes.randstr;
        this.login();
      }
    },
    storageUserInfo(userInfo) {
      localStorage.setItem("loginUserInfo", JSON.stringify(userInfo));
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.login {
  position: relative;
  height: 100vh;
  overflow: hidden;
  color: rgba($color: #409eff, $alpha: 0.8);
  .login-form {
    position: absolute;
    z-index: 9;
    right: 1.4479rem;
    top: 0.8698rem;
    background-image: url("@/assets/login-form-back.png");
    width: 2.401rem;
    height: 2.6146rem;
    box-sizing: border-box;
    padding: 0.1517rem 0.2917rem 0.217rem 0.2917rem;
    border: 0.0104rem solid;
    border-image: linear-gradient(0deg, #1e8cfd 0%, #52a8ff 100%) 2;
    .el-input {
      height: 0.2344rem;
      background-color: none !important;
      input {
        height: 0.2344rem;
      }
    }
    .el-input--prefix {
      background-color: none !important;
    }
    .input-icon {
      height: 0.2031rem;
      width: 0.0729rem;
      margin-left: 0.0104rem;
    }
  }
  .title {
    margin-bottom: 0.095rem;
    h3 {
      font-family: AlibabaPuHuiTi;
      font-size: 0.125rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .input-label {
    font-family: AlibabaPuHuiTi;
    font-size: 0.0833rem;
    font-weight: normal;
    color: #ffffff;
    margin-bottom: 0.0417rem;
  }
  .login-btn {
    font-size: 0.0729rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    letter-spacing: 0.0208rem;
  }
  .code-tip {
    line-height: 0.1042rem;
    font-size: 0.0729rem;
    margin-bottom: 0.2083rem;
    color: #fff;
  }

  .forget-password {
    display: inline-block;
    position: absolute;
    right: 0;
    font-family: PingFang SC;
    font-size: 0.0729rem;
    font-weight: normal;
    text-align: right;
    color: #ffffff;
  }

  .code-tips {
    position: absolute;
    right: 0;
    font-size: 0.0677rem;
    padding-top: 0.0521rem;
    line-height: 1;
  }
  .password-tips {
    position: absolute;
    right: 0;
    font-size: 0.0677rem;
    padding-top: 0.0521rem;
    line-height: 1;
  }
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #409eff !important;
  border: 0.0052rem solid #409eff !important;
}
/deep/ .el-checkbox__inner {
  background-color: #409eff !important;
  border: 0.0052rem solid #409eff !important;
}
input,
.el-slider__runway,
textarea,
.el-dialog input,
.el-switch__core,
.el-dialog .el-slider__runway,
.el-dialog textarea {
  background-color: #004177 !important;
  border-color: #4b82ee !important;
}
.el-dialog .el-input__inner {
  font-size: 0.0729rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #fff !important;
  ::placeholder {
    font-size: 0.0729rem !important;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(255, 255, 255, 0.5) !important;
  }
}
/deep/ .el-dialog {
  .el-dialog__header {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei-Regular;
    font-weight: 400;
    text-align: left;
    padding-top: 0.1667rem !important;
    padding-left: 0.1771rem !important;
    padding-bottom: 0px !important;
    &::before {
      content: "";
      width: 0.2396rem;
      height: 0.2656rem;
      border-top: 0.0104rem solid #2196f2;
      border-left: 0.0104rem solid #2196f2;
      position: absolute;
      top: 0px;
      left: 0px;
    }
    &::after {
      content: "";
      width: 0.2396rem;
      height: 0.2656rem;
      border-top: 0.0104rem solid #2196f2;
      border-right: 0.0104rem solid #2196f2;
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
  .el-dialog__headerbtn {
    z-index: 99;
  }
  .code-tips,
  .password-tips {
    font-size: 0.0729rem;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    top: 0.4167rem;
  }
  .el-dialog__body {
    padding: 0.1563rem 0.1875rem 0.2656rem 0.1771rem !important;
    &::before {
      content: "";
      width: 0.2396rem;
      height: 0.2656rem;
      border-bottom: 0.0104rem solid #2196f2;
      border-left: 0.0104rem solid #2196f2;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
    &::after {
      content: "";
      width: 0.2396rem;
      height: 0.2656rem;
      border-bottom: 0.0104rem solid #2196f2;
      border-right: 0.0104rem solid #2196f2;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }
  .el-input__inner {
    font-size: 0.0729rem;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #fff !important;
    height: 0.2083rem !important;
    ::placeholder {
      font-size: 0.0729rem !important;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 0.2552rem;
      height: 0.1719rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("../assets/btn-back.png");
      background-size: cover;
      font-size: 0.0833rem;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
    :hover {
      color: #1890ff;
    }
    .confirm {
      color: #fff;
      margin-left: 0.1823rem;
    }
    :hover {
      color: #1890ff;
    }
  }

  // .dialog-bottom {
  //   background: #fff;
  //   width: 100%;
  //   height: 51px;
  //   position: fixed;
  //   bottom: px;
  //   left: 0px;
  // }
  .el-dialog__title {
    font-size: 0.1146rem !important;
    color: #ffffff !important;
  }
}
/deep/ .el-dialog .el-form-item__label {
  font-size: 0.0833rem !important;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  padding-left: 0px;
  color: #fff !important;
}
/deep/ .el-checkbox {
  display: flex !important;
  align-items: center !important;
}
.el-checkbox {
  display: flex;
  align-items: center;
}
/deep/ .el-checkbox__input {
  position: relative;
  top: 0.0104rem;
}

/deep/ .el-checkbox__inner {
  background: rgba(59, 112, 234, 0.41) !important;
  border: 0.0052rem solid #ccd5e0 !important;
  border-radius: 0.0052rem !important;
  width: 0.0729rem;
  height: 0.0729rem;
}
/deep/ .is-checked .el-checkbox__inner {
  background: #409eff !important;
  border: 0.0104rem solid #4b82ee !important;
  border-radius: 0.0052rem !important;
  width: 0.0729rem;
  height: 0.0729rem;
}
.el-checkbox__inner {
  background: rgba(59, 112, 234, 0.41) !important;
  border: 0.0104rem solid #4b82ee !important;
  border-radius: 0.0052rem !important;
  width: 0.0729rem;
  height: 0.0729rem;
}
/deep/ .el-checkbox__label {
  font-family: PingFang SC;
  font-size: 0.0729rem;
  font-weight: normal;
  color: #ffffff;
}
/deep/ .is-checked .el-checkbox__label {
  font-family: PingFang SC;
  font-size: 0.0729rem;
  font-weight: normal;
  color: #ffffff !important;
}
/deep/ .el-checkbox__inner::after {
  border: 0.0104rem solid #fff;
  height: 0.0469rem;
  left: 0.0156rem;
  top: -0.0052rem;
  border-left: 0;
  border-top: 0;
}

.el-checkbox__inner::after {
  border: 0.0104rem solid #fff;
  height: 0.0625rem;
  left: 0.0313rem;
  top: 0.0156rem;
  border-left: 0;
  border-top: 0;
}
/deep/ .el-dialog {
  background: #004885 !important;
  color: #606266;
  margin-top: 6vh !important;
}
.el-dialog__title {
  color: #303133 !important;
  line-height: 0.125rem;
  font-size: 0.0938rem;
}
.el-dialog__body {
  padding: 0.1563rem 0.1042rem;
  color: #606266;
  font-size: 0.0729rem;
  word-break: break-all;
}
.el-form-item__label {
  color: #606266 !important;
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 0.0729rem !important;
  padding: 0 0.0625rem 0 0;
  padding-left: 0px !important;
  box-sizing: border-box;
  font-weight: 700;
}
/deep/ .el-input__inner {
  background: rgba(59, 112, 234, 0.41) !important;
  border: 0.0052rem solid rgba(35, 180, 255, 0.78) !important;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.0729rem;
  font-family: PingFang SC, PingFang SC-Regular;
  display: inline-block;
  height: 0.2083rem;
  line-height: 0.2083rem;
  outline: none;
  padding: 0 0.0781rem;
  width: 100%;
  ::placeholder {
    font-size: 0.0833rem !important;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(255, 255, 255, 1) !important;
  }
}
// 修改input自动填充改变颜色问题
/deep/ input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 0.3542rem rgba(2, 43, 99, 1) inset;
  -webkit-text-fill-color: #fff;
}
/deep/ input {
  background-color: rgba(2, 43, 99, 0.5) !important;
  caret-color: #fff;
  border: 0.0052rem solid #4b82ee !important;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.1094rem;
  font-family: PingFang SC, PingFang SC-Regular;
  display: inline-block;
  height: 0.2344rem;
  line-height: 0.2344rem;
  outline: none;
  padding: 0 0.0781rem;
  width: 100%;
  ::placeholder {
    font-size: 0.0729rem !important;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(255, 255, 255, 0.5) !important;
  }
}
.el-button {
  font-size: 0.0729rem !important;
  padding: 0.0521rem 0.1042rem !important;
  border-radius: 0.0208rem !important;
}
.el-form-item {
  margin-top: 0px !important;
  margin-bottom: 0.1042rem !important;
  &:last-child {
    margin-bottom: 0 !important;
  }
}
.el-link--default {
  font-size: 0.0729rem;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}
/deep/ .el-input .el-input__clear {
  color: rgba(255, 255, 255, 0.47);
}
/deep/ .el-link--default:hover {
  color: #1890ff;
}
/deep/ .el-link:hover {
  color: #1890ff !important;
}
.el-button--default {
  background-color: #fff !important;
  color: #606266 !important;
  border-color: #dcdfe6 !important;
}
.el-button:hover {
  color: #1890ff !important;
}
/deep/ .el-button--default:hover {
  color: #1890ff !important;
  border-color: #badeff !important;
  background-color: #e8f4ff !important;
}
.el-button--primary {
  background: linear-gradient(180deg, #4f9eff, #0091ea) !important;
  border-radius: 0.0417rem;
  // background-color: #409eff !important;
  color: #fff !important;
  // border-color: #409eff !important;
}
.el-button--primary:hover {
  color: #fff !important;
  border-color: #409eff !important;
  background-color: rgba($color: #409eff, $alpha: 0.8) !important;
}
/deep/ .el-form-item__content {
  padding-right: 0px !important;
}
.el-dialog__close {
  color: rgba($color: #fff, $alpha: 0.8);
}
</style>
