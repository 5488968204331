import top from "../../components/chart/top.vue";
import footerCom from "../../components/chart/footer.vue";
import titleCom from "../../components/chart/title.vue";
import longTitleCom from "../../components/chart/long-title.vue";
import lineChart from "../../components/echart/line.vue";
import pieChart from "../../components/echart/pie.vue";
import pieTask from "../../components/echart/pie-task.vue";
import logout from "../../components/chart/logout.vue";
import radarChart from "../../components/echart/radar.vue";
import dayjs from "dayjs";
import * as echarts from "echarts";
import { logisticsCommand, inspectionProgress, rectifyRadar, rectifyStatistic, inspectionAnalyse2, improvementTable, improvementNightTing, shopTopRed, shopTopBlack, checkRecordOnTime, rulesList, rulesTableList,xjHead, xjTable,blackDetail,shopTopBlack2 } from "@/api/digital-inspection";
export default {
  components: { top, titleCom, lineChart, pieChart, radarChart, pieTask, longTitleCom, footerCom, logout },
  data() {
    return {
      redScore: 100,
      ruleTable: [],
      ruleList: [],
      widthPix: 1,
      timer: null,
      // 后勤指挥巡检
      logisticsData: {
        userCount: 0,
        checkCount: 0,
        offLineCount: 0,
        onLineCount: 0,
      },
      // 本月食堂巡检进度
      inspectionProgressData: {
        offLineCount: 0,
        onLineCount: 0,
        shopCount: 0,
      },
      // 本月整改分布 --- 雷达图
      rectifyRadarData: {
        againCount: 0,
        completeCount: 0,
        failCount: 0,
        rectifyRadarCount: 0,
        unVerifyCount: 0,
        undoCount: 0,
      },
      // 本月整改分布右侧数据
      rectifyData: [
        [
          {
            shopName: "合川一食堂",
            rectifyCount: 0,
          },
          {
            shopName: "合川二食堂",
            rectifyCount: 0,
          },
          {
            shopName: "合川三食堂",
            rectifyCount: 0,
          },
          {
            shopName: "华岩一食堂",
            rectifyCount: 0,
          },
        ],
      ],
      //    本月食堂巡检分析
      shopDataMonth: [
        [
          {
            index: 1,
            shopName: "合川一食堂",
            offlineScore: 0,
            offlineRectifyCount: 0,
            onlineScore: 0,
            onlineRectifyCount: 0,
          },
          {
            index: 1,
            shopName: "合川二食堂",
            offlineScore: 0,
            offlineRectifyCount: 0,
            onlineScore: 0,
            onlineRectifyCount: 0,
          },
          {
            index: 1,
            shopName: "合川三食堂",
            offlineScore: 0,
            offlineRectifyCount: 0,
            onlineScore: 0,
            onlineRectifyCount: 0,
          },
          {
            index: 1,
            shopName: "华岩一食堂",
            offlineScore: 0,
            offlineRectifyCount: 0,
            onlineScore: 0,
            onlineRectifyCount: 0,
          },
          {
            index: 1,
            shopName: "华岩三食堂",
            offlineScore: 0,
            offlineRectifyCount: 0,
            onlineScore: 0,
            onlineRectifyCount: 0,
          },
          {
            index: 1,
            shopName: "石桥铺食堂",
            offlineScore: 0,
            offlineRectifyCount: 0,
            onlineScore: 0,
            onlineRectifyCount: 0,
          },
        ],
      ],
      // 近3月重点提升方向
      improvementData: [
        [
          {
            index: 0,
            problemTopicCategory: "卫生情况",
            tableTopicName: "卫生",
            rectifyCount: 0,
          },
          {
            index: 1,
            problemTopicCategory: "卫生情况",
            tableTopicName: "卫生",
            rectifyCount: 0,
          },
          {
            index: 2,
            problemTopicCategory: "卫生情况",
            tableTopicName: "卫生",
            rectifyCount: 0,
          },
          //   {
          //     index: 3,
          //     problemTopicCategory: "卫生情况",
          //     tableTopicName: "卫生",
          //     rectifyCount: 0,
          //   },
        ],
      ],
      // 红榜数据
      redData: [
        {
          shopName: "合川一食堂",
          score: 0,
        },
        {
          shopName: "合川二食堂",
          score: 0,
        },
        {
          shopName: "合川三食堂",
          score: 0,
        },
        {
          shopName: "华岩三食堂",
          score: 0,
        },
        {
          shopName: "华岩一食堂",
          score: 0,
        },
      ],
      // 黑榜数据
      blackData: [
        {
          shopName: "合川一食堂",
          problemCount: 0,
          rate: 0,
        },
        {
          shopName: "合川二食堂",
          problemCount: 0,
          rate: 0,
        },
        {
          shopName: "合川三食堂",
          problemCount: 0,
          rate: 0,
        },
        {
          shopName: "华岩三食堂",
          problemCount: 0,
          rate: 0,
        },
        {
          shopName: "华岩一食堂",
          problemCount: 0,
          rate: 0,
        },
      ],
      // 重点提升右侧图形表述
      improvementChartDescData: [],
      onTimeData: [
        [
          {
            content: "某某某对行园餐厅进行了督导巡检，得分80分",
            checkTime: "7/19 09:08",
          },
          {
            content: "某某某对行园餐厅进行了督导巡检，得分80分",
            checkTime: "7/19 09:08",
          },
          {
            content: "某某某对行园餐厅进行了督导巡检，得分80分",
            checkTime: "7/19 09:08",
          },
          {
            content: "某某某对行园餐厅进行了督导巡检，得分80分",
            checkTime: "7/19 09:08",
          },
        ],
      ],

      // 巡检进度timer
      progressTimer: null,
      // 雷达图timer
      radarTimer: null,
      // 巡检分析timer
      analyseTimer: null,
      // 南丁timer
      nightTingTimer: null,
      timer2: null,
      showFilpper: false,
      flipperTimer: null,
      // 红黑榜
      redTimer: null,
      redTrue: false,
      blackTimer: null,
      blackTrue: false,
      ruleId: undefined,
      xjDiaShow: false,
      tableLoading:false,
      xjTableList: [
      ],
      xjheadList: [
        {
          label: "已巡检",
          num: 10,
          type: 1,
          isChoose: true,
        },
        {
          label: "未巡检",
          num: 20,
          type: 2,
          isChoose: false,
        },
      ],
      queryParams: {
        current: 1,
        size: 10,
        shopId: undefined,
      },
      xjLabel: "",
      total:0,
      dzgDiaShow: false,
      safeScoreShopList:[],
      safeScoreList: [],
      shopScoreParams: {
        shopId: 0,
        grade: 1,
        current: 1,
        size: 10,
      },
      isDzgClick:false,
      safeScoreShow:false,
      scoreChooseList: [
        {
          isChoose: true,
          problemCount: 13,
        },
        {
          isChoose: false,
          problemCount: 0,
        },
        {
          isChoose: false,
          problemCount: 13,
        },
      ],
    };
  },
  methods: {
    getShopScoreList() {
      // shopScoreDetailPage(this.shopScoreParams).then((res) => {
      //   this.safeScoreList = res.data.records;
      //   if (this.safeScoreList.length > 0) {
      //     const nodataList = Array(10 - this.safeScoreList.length).fill({ fillFlag: true });
      //     this.safeScoreList = this.safeScoreList.concat(nodataList);
      //   }
      //   this.total = res.data.total;
      // });
    },
    chooseShopRIskHead(item, index) {
      this.queryParams.current = 1;

      this.queryParams.type = item.type == 1 ? 1 : 0;

      this.xjTableList = [];
      this.xjheadList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.xjheadList, index, item);
      this.getxjTable();
    },
    showXujianDia(type) {
      this.queryParams.current = 1;
      if (type == 1) {
        this.xjLabel = "督查巡检详情";
        this.queryParams.checkWay = 10;
        this.queryParams.type = 1;

        xjHead({ checkWay: 10 }).then((res) => {
          this.xjheadList = [
            {
              label: "已巡检",
              num: res.data.checkShops,
              type: 1,
              isChoose: true,
            },
            {
              label: "未巡检",
              num: res.data.unCheckShops,
              type: 2,
              isChoose: false,
            },
          ];
        });
        this.getxjTable();
      } else {
        this.xjLabel = "视频巡检详情";
        this.queryParams.checkWay = 20;
        this.queryParams.type = 1;

        xjHead({ checkWay: 20 }).then((res) => {
          this.xjheadList = [
            {
              label: "已巡检",
              num: res.data.checkShops,
              type: 1,
              isChoose: true,
            },
            {
              label: "未巡检",
              num: res.data.unCheckShops,
              type: 2,
              isChoose: false,
            },
          ];
        });
        this.getxjTable();
      }
      this.xjDiaShow = true;
    },
    // 获取巡检表格
    getxjTable() {
      this.tableLoading = true;
      this.xjTableList = [];
      xjTable(this.queryParams).then((res) => {
        this.xjTableList = res.data.records || [];
        if (this.xjTableList.length > 0 && this.xjTableList.length < 10) {
          const nodataList = Array(10 - this.xjTableList.length).fill({ fillFlag: true });
          this.xjTableList = this.xjTableList.concat(nodataList);
        }
        this.total = res.data.total;
        this.tableLoading = false;
      });
    },
    getRulesTableList(ruleId) {
      this.ruleTable = [];
      rulesTableList({ ruleId: ruleId }).then((res) => {
        for (let i = 0; i < res.data.length; i += 5) {
          this.ruleTable.push(res.data.slice(i, i + 5));
        }
      });
    },
    ruleChange(e) {
      this.ruleId = e;
      this.getRulesTableList(e);
    },
    getRuleList() {
      rulesList().then((res) => {
        if (res.code == 200) {
          this.ruleList = res.data;
          this.ruleId = res.data[0].logRuleId;
          this.getRulesTableList(res.data[0].logRuleId);
        }
      });
    },
    requestList() {
      // 后勤指挥巡检数据
      this.getLogisticsCommandData();
      // 本月食堂巡检进度
      this.getInspectionProgressData();
      // 本月整改分布 --- 雷达图
      this.getRectifyRadarData();
      // 本月整改分布 --- 整改统计
      // this.getRectifyStatisticData()
      // 本月食堂巡检分析
      this.getInspectionAnalyseData();
      // 重点提升方向左侧
      this.getImprovementTableData();
      // 南丁格尔
      this.getImprovementNightTingData();
      // 红榜
      this.getRedData();
      // 黑榜
      this.getBlackData();
      // 实时巡查情况
      this.getCheckRecordOnTimeData();
    },
    scoreDetailShopClick(item, index) {
      this.queryParams.current = 1;
      this.queryParams.shopId = item.shopId;
      this.safeScoreShopList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.safeScoreShopList, index, item);
      this.balckPage();
      // this.shopScoreParams.shopId = item.shopId;
      // this.shopScoreParams.current = 1;
      // this.shopScoreParams.grade = 1;
      // this.getShopScoreList();
      // this.getScoreTop();
    },
    // 后勤指挥巡检数据
    getLogisticsCommandData() {
      this.showFilpper = false;
      logisticsCommand().then((res) => {
        if (this.flipperTimer) {
          clearInterval(this.flipperTimer);
          this.flipperTimer = null;
        }
        this.logisticsData = res.data;
        if (!this.logisticsData.checkCount) {
          this.logisticsData.checkCount = 0;
        }
        this.showFilpper = true;
        this.flipperTimer = setInterval(() => {
          this.showFilpper = false;
          setTimeout(() => {
            this.showFilpper = true;
          });
        }, 30000);
      });
    },
    // 本月食堂巡检进度
    getInspectionProgressData() {
      inspectionProgress().then((res) => {
        const data = res.data;
        this.inspectionProgressData.offLineCount = data.offLineCount;
        this.inspectionProgressData.onLineCount = data.onLineCount;
        this.inspectionProgressData.shopCount = data.shopCount;
        const offLineCountNoCheck = data.shopCount - data.offLineCount;
        const onLineCountNoCheck = data.shopCount - data.onLineCount;
        const offLineRate = Math.round((data.offLineCount / data.shopCount) * 100);
        const onLineRate = Math.round((data.onLineCount / data.shopCount) * 100);
        const offLineSeriesData = this.dealProgressPieData(data.offLineCount, offLineCountNoCheck, "offLine");
        const onLineSeriesData = this.dealProgressPieData(data.onLineCount, onLineCountNoCheck, "onLine");
        this.$nextTick(() => {
          this.$refs.taskPie.initChart(offLineSeriesData, offLineRate + "%", "督导巡检");
          this.$refs.taskPieVideo.initChart(onLineSeriesData, onLineRate + "%", "视频巡检");

          if (this.progressTimer) {
            clearInterval(this.progressTimer);
            this.progressTimer = null;
          }
          this.progressTimer = setInterval(() => {
            this.$refs.taskPie.initChart(offLineSeriesData, offLineRate + "%", "督导巡检");
            this.$refs.taskPieVideo.initChart(onLineSeriesData, onLineRate + "%", "视频巡检");
          }, 60000);
        });
      });
    },
    dealProgressPieData(complete, inComplete, type) {
      return [
        {
          type: "pie",
          radius: ["85%", "95%"],
          center: ["50%", "50%"],
          color: type === "offLine" ? ["#318EFF", "#C8D5EB"] : ["#4FDC5D", "#C8D5EB"],
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: "#000",
            borderWidth: 5 * this.widthPix,
          },
          data: [
            { value: complete, name: "已完成" },
            { value: inComplete, name: "未完成" },
          ],
        },
        {
          type: "pie",
          radius: ["60%", "85%"],
          center: ["50%", "50%"],
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: "#000",
            borderWidth: 5,
          },
          data: [
            {
              value: complete,
              name: "已完成",
              itemStyle: {
                color: type === "offLine" ? "rgba(84, 155, 241, 0.28)" : "rgba(60, 187, 73, 0.3)",
              },
            },
            {
              value: inComplete,
              name: "未完成",
              itemStyle: {
                color: "rgba(146, 166, 198, 0.3)",
              },
            },
          ],
        },
      ];
    },
    // 本月整改分布
    // 雷达图
    getRectifyRadarData() {
      this.isDzgClick = true;
      rectifyRadar().then((res) => {
        const data = res.data;
        this.rectifyRadarData.completeCount = data.completeCount;
        this.rectifyRadarData.undoCount = data.undoCount;
        this.rectifyRadarData.failCount = data.failCount;
        this.rectifyRadarData.againCount = data.againCount;
        this.rectifyRadarData.unVerifyCount = data.unVerifyCount;
        this.rectifyRadarData.rectifyRadarCount = data.completeCount + data.undoCount + data.failCount + data.againCount + data.unVerifyCount;
        const indicator = [
          { name: "整改成功 " + data.completeCount, max: this.rectifyRadarData.rectifyRadarCount },
          { name: "待整改 " + data.undoCount, max: this.rectifyRadarData.rectifyRadarCount },
          { name: "整改失败 " + data.failCount, max: this.rectifyRadarData.rectifyRadarCount },
          { name: "重新整改 " + data.againCount, max: this.rectifyRadarData.rectifyRadarCount },
          { name: "待审核 " + data.unVerifyCount, max: this.rectifyRadarData.rectifyRadarCount },
        ];
        const radarData = [data.completeCount, data.undoCount, data.failCount, data.againCount, data.unVerifyCount];
        const radarOutData = Array(5).fill(this.rectifyRadarData.rectifyRadarCount);
        this.$nextTick(() => {
          this.$refs.radar.initChart(indicator, radarData, radarOutData);

          if (this.radarTimer) {
            clearInterval(this.radarTimer);
            this.radarTimer = null;
          }
          this.radarTimer = setInterval(() => {
            this.$refs.radar.initChart(indicator, radarData, radarOutData);
          }, 60000);
        });
      });
    },
    dzgClick2(item) {
      console.log(item);
      this.dzgDiaShow = item.isShow;
      shopTopBlack2().then((res) => {
        this.safeScoreShopList = res.data.sort((a, b) => (Number(a.problemCount) < Number(b.problemCount) ? 1 : -1));
        const problemCount = this.safeScoreShopList.map((e) => Number(e.problemCount));
        const totalProblemCount = problemCount.reduce((pre, next) => pre + next);
        this.safeScoreShopList.forEach((e) => {
          const score = Number(e.problemCount);
          e.rate = Math.round((score / totalProblemCount) * 100) + "%";
        });

        this.safeScoreShopList.forEach((e) => {
          e.isChoose = false;
        });
        this.safeScoreShopList[0].isChoose = true;
        this.queryParams.shopId = this.safeScoreShopList[0].shopId;
        this.queryParams.current = 1;
        this.balckPage();
      });
    },
    blackDetailClick(item, index) {
      this.queryParams.current = 1;
      this.queryParams.shopId = item.shopId;
      this.safeScoreList = [];
      this.scoreChooseList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.scoreChooseList, index, item);
      this.balckPage();
      this.safeScoreShow = true;
    },
    chooseScoreIt(item, index) {
      this.queryParams.current = 1;
      console.log('item',item)
      this.queryParams.shopId = item.shopId;
      this.scoreChooseList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.scoreChooseList, index, item);
      this.balckPage();
    },
    async balckPage() {
      this.tableLoading = true;
      this.safeScoreList = [];
      await blackDetail(this.queryParams).then((res) => {
        this.safeScoreList = res.data.records || [];
        if (this.safeScoreList.length > 0 && this.safeScoreList.length < 10) {
          const nodataList = Array(10 - this.safeScoreList.length).fill({ fillFlag: true });
          this.safeScoreList = this.safeScoreList.concat(nodataList);
        }
        this.total = res.data.total;
        this.tableLoading = false;
      });
    },
    // 整改统计
    getRectifyStatisticData() {
      rectifyStatistic().then((res) => {
        const data = res.data;
        data.forEach((e, index) => {
          e.index = index + 1;
        });
        this.rectifyData = [];
        const loopNum = Math.ceil(data.length / 6); // 四次一页
        for (let index = 0; index < loopNum; index++) {
          this.rectifyData.push(data.splice(0, 6));
        }
      });
    },
    // 本月食堂巡检分析
    getInspectionAnalyseData() {
      inspectionAnalyse2().then((res) => {
        const data = res.data;
        const tableData = JSON.parse(JSON.stringify(data));
        tableData.forEach((e, index) => {
          e.index = index + 1;
        });
        this.shopDataMonth = [];
        const loopNum = Math.ceil(tableData.length / 6);
        for (let index = 0; index < loopNum; index++) {
          this.shopDataMonth.push(tableData.splice(0, 6));
        }
        const category = data.map((e) => e.shopName);
        const onLineScore = data.map((e) => Number(e.onlineScore));
        const offLineScore = data.map((e) => Number(e.offlineScore));
        const series = [
          {
            name: "视频巡检",
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(43, 62, 81, 0.6)",
            },
            barWidth: 10 * this.widthPix,
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                color: "#15B5FF",
                fontSize: this.widthPix * 11,
              },
              formatter: function (params) {
                return params.value;
              },
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: " rgba(21,177,255,0.30)" },
                // { offset: 0.5, color: "#64C56E" },
                { offset: 1, color: " #15B5FF" },
              ]),
            },
            data: onLineScore,
          },
          {
            name: "督导巡检",
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(43, 62, 81, 0.6)",
            },
            barGap: 1,
            barWidth: 10 * this.widthPix,
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                color: "#D0DEEE",
                fontSize: this.widthPix * 11,
              },
              formatter: function (params) {
                console.log(params);
                //核心部分 formatter 可以为字符串也可以是回调

                return params.value;
              },
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(208,222,238,0.30)" },
                // { offset: 0.5, color: "#4F8DFF" },
                { offset: 1, color: "#D0DEEE" },
              ]),
            },
            data: offLineScore,
          },
        ];
        this.$refs.scoreLine.initChart(series, category);
        if (this.analyseTimer) {
          clearInterval(this.analyseTimer);
          this.analyseTimer = null;
        }
        this.analyseTimer = setInterval(() => {
          this.$refs.scoreLine.initChart(series, category);
        }, 60000);
      });
    },
    // 重点提升方向左侧
    getImprovementTableData() {
      improvementTable().then((res) => {
        const data = res.data;
        data.forEach((e, index) => {
          e.index = index + 1;
        });
        this.improvementData = [];
        // for (let i = 0; i < e.length; i += 2) {
        //     swiperList.push(e.slice(i, i + 2));
        //   }
        const loopNum = Math.ceil(data.length / 3); // 四次一页
        for (let index = 0; index < loopNum; index++) {
          this.improvementData.push(data.splice(0, 3));
        }
      });
    },
    // 南丁格尔
    getImprovementNightTingData() {
      improvementNightTing().then((res) => {
        const pieColors = ["rgba(84, 155, 241, 0.28)", "rgba(49, 241, 255, 0.28)", "rgba(79, 220, 93, 0.28)", "rgba(255, 121, 87, 0.28)", "rgba(255, 205, 88, 0.28)", "rgba(161, 174, 195, 0.28)", "(161, 174, 195, 0.13)", "(161, 174, 195, 0.08)"];
        let data = res.data;
        data = data.sort((a, b) => (a.appearAccount > b.appearAccount ? -1 : 1));
        const nums = data.map((e) => e.appearAccount);
        const total = nums.reduce((pre, next) => pre + next);
        const seriesData = [];
        const borderColor = ["#318EFF", "#31F1FF", "#4FDC5D", "#FF7957", "#FFCD58", "#A1AEC3", "rgba(161, 174, 195, 0.6)", "rgba(161, 174, 195, 0.34)"];
        data.forEach((e, index) => {
          e.rate = ((e.appearAccount / total) * 100).toFixed(1) + "%";

          const pieObj = {
            value: e.appearAccount,
            itemStyle: {
              color: pieColors[index],
              borderWidth: this.widthPix * 2,
              borderColor: borderColor[index],
            },
          };
          seriesData.push(pieObj);
        });
        this.improvementChartDescData = data;
        const series = [
          {
            type: "pie",
            radius: [0, "90%"],
            center: ["50%", "50%"],
            roseType: "radius",
            label: {
              show: false,
            },
            data: seriesData,
          },
        ];
        setTimeout(() => {
          this.$refs.improvementPie.initChart(series);
          if (this.nightTingTimer) {
            clearInterval(this.nightTingTimer);
            this.nightTingTimer = null;
          }
          this.nightTingTimer = setInterval(() => {
            this.$refs.improvementPie.initChart(series);
          }, 60000);
        }, 200);
      });
    },
    // 红榜
    getRedData() {
      shopTopRed().then((res) => {
        if (this.redTimer) {
          clearInterval(this.redTimer);
          this.redTimer = null;
        }
        this.redTrue = true;
        this.redData = res.data.sort((a, b) => (Number(a.score) < Number(b.score) ? 1 : -1));
        // let num = 0;
        // this.redData.forEach((e) => {
        //   num = Number(e.score) + num;
        // });
        this.redScore = 100;
        setInterval(() => {
          this.redTrue = false;
          setTimeout(() => {
            this.redTrue = true;
            // this.redData.forEach(e=>e.score+=10)
          });
        }, 10000);
      });
    },
    // 黑榜
    getBlackData() {
      shopTopBlack().then((res) => {
        if (this.blackTimer) {
          clearInterval(this.blackTimer);
          this.blackTimer = null;
        }
        this.blackTrue = true;
        this.blackData = res.data.sort((a, b) => (Number(a.problemCount) < Number(b.problemCount) ? 1 : -1));
        const problemCount = this.blackData.map((e) => Number(e.problemCount));
        const totalProblemCount = problemCount.reduce((pre, next) => pre + next);
        this.blackData.forEach((e) => {
          const score = Number(e.problemCount);
          e.rate = score==0?'0%':Math.round((score / totalProblemCount) * 100) + "%";
        });
        this.scoreChooseList = [...this.blackData];
        this.scoreChooseList.forEach((e) => {
          e.isChoose = false;
        });
        setInterval(() => {
          this.blackTrue = false;
          setTimeout(() => {
            this.blackTrue = true;
          });
        }, 60000);
      });
    },
    // 实时巡查情况
    getCheckRecordOnTimeData() {
      checkRecordOnTime().then((res) => {
        // checkWay = 10 checkType 1 督导巡检 2 食堂自检 3 任务巡检
        // checkWay = 10 checkType 1 视频巡检
        this.onTimeData = [];

        res.data.forEach((e) => {
          if (e.checkWay == 10) {
            if (e.checkType == 1) {
              e.checkTypeName = "督导巡检";
            } else if (e.checkType == 2) {
              e.checkTypeName = "食堂自检";
            } else if (e.checkType == 3) {
              e.checkTypeName = "任务巡检";
            }
          } else {
            if (e.checkType == 1) {
              e.checkTypeName = "视频巡检";
            }
          }
          e.checkTime = dayjs(e.createTime).format("M/D HH:mm");
          e.content = `
                        ${e.userName} 对 ${e.shopName}进行${e.checkTypeName}，得分
                        ${e.getScore}分。
                    `;
        });
        const loopNum = Math.ceil(res.data.length / 6);
        for (let index = 0; index < loopNum; index++) {
          this.onTimeData.push(res.data.splice(0, 6));
        }
      });
    },
  },
  mounted() {
    this.widthPix = window.screen.width < 1920 ? 1 : Math.floor(window.screen.width / 1920);
    this.$refs.taskPie.initChart(0, 0 + "%", "督导巡检");
    this.$refs.taskPieVideo.initChart(0, 0 + "%", "视频巡检");
    const indicator = [
      { name: "整改成功 " + 0, max: 0 },
      { name: "待整改 " + 0, max: 0 },
      { name: "整改失败 " + 0, max: 0 },
      { name: "重新整改 " + 0, max: 0 },
      { name: "待审核 " + 0, max: 0 },
    ];
    this.$refs.radar.initChart(indicator, [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]);
    const category = ["合川一食堂", "合川二食堂", "合川三食堂", "华岩一食堂", "华岩三食堂"];
    const series = [
      {
        name: "督导巡检",
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(43, 62, 81, 0.6)",
        },
        barWidth: 10 * this.widthPix,

        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#83bff6" },
            { offset: 0.5, color: "#4F8DFF" },
            { offset: 1, color: "#4F8DFF" },
          ]),
        },
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "视频巡检",
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(43, 62, 81, 0.6)",
        },
        barWidth: 10 * this.widthPix,

        barGap: 1,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#fff" },
            { offset: 0.5, color: "#64C56E" },
            { offset: 1, color: "#64C56E" },
          ]),
        },
        data: [0, 0, 0, 0, 0, 0, 0, 0],
      },
    ];
    this.$refs.scoreLine.initChart(series, category);

    const data = [
      {
        index: 1,
        topicType: "卫生情况",
        appearAccount: 0,
        rate: "0%",
      },
      {
        index: 2,
        topicType: "卫生情况",
        appearAccount: 0,
        rate: "0%",
      },
      {
        index: 3,
        topicType: "卫生情况",
        appearAccount: 0,
        rate: "0%",
      },
      {
        index: 4,
        topicType: "卫生情况",
        appearAccount: 0,
        rate: "0%",
      },
    ];
    const pieColors = ["rgba(84, 155, 241, 0.28)", "rgba(49, 241, 255, 0.28)", "rgba(79, 220, 93, 0.28)", "rgba(255, 121, 87, 0.28)", "rgba(255, 205, 88, 0.28)", "rgba(161, 174, 195, 0.28)", "(161, 174, 195, 0.13)", "(161, 174, 195, 0.08)"];
    const seriesData = [];
    const borderColor = ["#318EFF", "#31F1FF", "#4FDC5D", "#FF7957", "#FFCD58", "#A1AEC3", "rgba(161, 174, 195, 0.6)", "rgba(161, 174, 195, 0.34)"];
    data.forEach((e, index) => {
      e.rate = Math.round((e.appearAccount / 0) * 100) + "%";

      const pieObj = {
        value: e.appearAccount,
        itemStyle: {
          color: pieColors[index],
          borderWidth: this.widthPix * 2,
          borderColor: borderColor[index],
        },
      };
      seriesData.push(pieObj);
    });
    this.improvementChartDescData = data;
    const seriesNing = [
      {
        type: "pie",
        radius: [0, "90%"],
        center: ["50%", "50%"],
        roseType: "radius",
        label: {
          show: false,
        },
        data: seriesData,
      },
    ];
    this.$nextTick(() => {
      this.$refs.improvementPie.initChart(seriesNing);
    });

    this.getRectifyStatisticData();
    this.requestList();
    this.getRuleList();
    this.timer = setInterval(() => {
      this.requestList();
    }, 300000);
    this.timer2 = setInterval(() => {
      this.getRectifyStatisticData();
    }, 120000);
  },
  activated() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.requestList();
      }, 300000);
    }
    if (!this.timer2) {
      this.timer2 = setInterval(() => {
        this.getRectifyStatisticData();
      }, 120000);
    }
  },
  deactivated() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    clearInterval(this.progressTimer);
    clearInterval(this.radarTimer);
    clearInterval(this.analyseTimer);
    clearInterval(this.nightTingTimer);
    clearInterval(this.flipperTimer);
    clearInterval(this.redTimer);
    clearInterval(this.blackTimer);
    this.timer = null;
    this.timer2 = null;
    this.progressTimer = null;
    this.radarTimer = null;
    this.analyseTimer = null;
    this.nightTingTimer = null;
    this.flipperTimer = null;
    this.redTimer = null;
    this.blackTimer = null;
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    clearInterval(this.progressTimer);
    clearInterval(this.radarTimer);
    clearInterval(this.analyseTimer);
    clearInterval(this.nightTingTimer);
    clearInterval(this.flipperTimer);
    clearInterval(this.redTimer);
    clearInterval(this.blackTimer);
    this.timer = null;
    this.timer2 = null;
    this.progressTimer = null;
    this.radarTimer = null;
    this.analyseTimer = null;
    this.nightTingTimer = null;
    this.flipperTimer = null;
    this.redTimer = null;
    this.blackTimer = null;
  },
};
