import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import dayjs from "dayjs";
Vue.config.productionTip = false;
import ProgressiveImage from 'progressive-image/dist/vue'
import VueLoopScroll from 'vueloopscroll'
import 'vueloopscroll/lib/vueloopscroll.css';
Vue.use(VueLoopScroll)
Vue.use(ProgressiveImage, {
  removePreview: true,
  scale:true
});
import Pagination from "@/components/Pagination";
Vue.component("Pagination", Pagination);

Vue.prototype.dayjs = dayjs;
Vue.prototype.msgSuccess = function(msg) {
  this.$message({
      showClose: true,
      message: msg,
      type: "success",
  });
};

Vue.prototype.msgError = function(msg) {
  this.$message({
      showClose: true,
      message: msg,
      type: "error",
  });
};

Vue.prototype.msgWarn = function(msg) {
  this.$message({
      showClose: true,
      message: msg,
      type: "warning",
  });
};

Vue.prototype.msgInfo = function(msg) {
  this.$message.info(msg);
};

Vue.directive("flipper", {
  bind(el, bindings) {
    let timer = null;
    let start = 0;
    let end = bindings.value;
    let endLength = end + ''
    let finishValue = 0;
    let otherNum = '';
    let delay = 0;
    if(endLength.length>1){
      finishValue = Number(endLength[endLength.length - 1])
      otherNum = endLength.slice(0,endLength.length-1)
    }else{
      finishValue = Number(endLength);
      otherNum = '';
    }
    timer = setInterval(() => {
      if (finishValue == 0) {
        clearInterval(timer);
        timer = null;
      } else {
        start += 1;
        if (start >= finishValue) {
          clearInterval(timer);
          timer = null;
        }
      }
      el.innerText =otherNum?otherNum+start:start;
    }, 200);
  },
});
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
