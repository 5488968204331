<template>
  <div class="chart" ref="radar"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      widthPix: 1,
    };
  },
  props: {
    isClick: {
      default: false,
    },
  },
  methods: {
    initChart(indicator, data, radarOutData) {
      let myChart = null;
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose();
      }
      var chartDom = this.$refs.radar;
      myChart = echarts.init(chartDom);
      //添加点击事件
      if (this.isClick) {
        myChart.on("click", (params) => {
          //定义参数-name

          let isShow = false;
          if (params.name.indexOf("待整改") != -1) {
            isShow = true;
          }
          this.$emit("dzgClick", {
            isShow: isShow,
          });
        });
      }

      var option;
      option = {
        color: ["#4FDC5D"],
        radar: {
          indicator: indicator,
          radius: ["0%", "68%"],
          center: ["50%", "60%"],
          triggerEvent: true,
          splitLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.17)",
              type: "solid",
              width: this.widthPix * 1,
            },
          },
          axisName: {
            formatter: (value, indicator) => {
              const values = value.split(" ");
              return values[0] + "\n" + values[1];
            },
            color: "#BCD2ED",
          },
          itemStyle: {
            width: this.widthPix * 1,
          },
          splitArea: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.17)",
              type: "solid",
            },
            width: this.widthPix * 1,
          },
        },
        grid: {},
        series: [
          {
            type: "radar",
            symbol: "none",
            areaStyle: {
              color: "rgba(79, 220, 93, 0.18)",
            },
            data: [
              {
                value: data,
                name: "",
              },
            ],
          },
          {
            type: "radar",
            symbol: "circle",
            symbolSize: this.widthPix * 3,

            itemStyle: {
              color: "#318EFF",
            },
            lineStyle: {
              color: "rgba(255, 255, 255, 0.17)",
            },
            data: [
              {
                value: radarOutData,
                name: "",
              },
            ],
          },
        ],
      };
      myChart.clear();
      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.widthPix = window.screen.width < 1920 ? 1 : Math.floor(window.screen.width / 1920);
    // this.initChart();
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
}
</style>
