import { render, staticRenderFns } from "./device-title.vue?vue&type=template&id=52cd1c89&scoped=true"
import script from "./device-title.vue?vue&type=script&lang=js"
export * from "./device-title.vue?vue&type=script&lang=js"
import style0 from "./device-title.vue?vue&type=style&index=0&id=52cd1c89&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52cd1c89",
  null
  
)

export default component.exports