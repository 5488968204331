/**
 * @file request.js
 */
 import axios from "axios";
 import { MessageBox, Message } from "element-ui";
 import store from "@/store";
 import { getToken } from "@/utils/auth";
 axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
 import md5 from "js-md5";
 import { getUuid } from "@/utils/common";
 // 创建axios实例
 const request = axios.create({
     // axios中请求配置有baseURL选项，表示请求URL公共部分
     baseURL: '/dashboard-api',
     // 超时
     timeout: 500
 });
 request.defaults.timeout = 2000000;
 const md5Salt = '51sd49g90et98082w90r3il8ml2v05k6';
 const version = '3.0';
 // request拦截器
 request.interceptors.request.use(
     config => {
         if (getToken()) {
             let token = "Bearer " + getToken();
             let nonce = getUuid();
             let timestamp = new Date().getTime();
 
             config.headers["Authorization"] = token;
             config.headers["nonce"] = nonce;
             config.headers["timestamp"] = timestamp;
             config.headers["sign"] = md5(
                 token + md5Salt + timestamp + "" + nonce + version
             );
         }
         // 路由中的参数
         let params = config.params;
         var newParams = {};
         for (var param in params) {
             if (params[param] !== undefined) {
                 if (typeof params[param] === "string") {
                     newParams[param] =
                         params[param].indexOf("[") != -1 ||
                         params[param].indexOf("]") != -1
                             ? encodeURIComponent(params[param]).trim()
                             : params[param].trim();
                 } else {
                     newParams[param] = params[param];
                 }
             }
         }
         config.params = newParams;
         // 表单参数
         if (config.data) {
             params = config.data;
             for (var key in params) {
                 if (typeof params[key] == "string") {
                     params[key] = params[key].trim();
                 }
             }
         }
 
         return config;
     },
     error => {
         Promise.reject(error);
     }
 );
 
 // 响应拦截器
 request.interceptors.response.use(
     res => {
         const code = res.data.code;
         if (code === 401) {
          store.dispatch("LogOut").then(() => {
            location.href = '/login'
            
          });
         } else {
             return res.data;
         }
     },
     error => {
        //  Message({
        //      message: error.message,
        //      type: "error",
        //      duration: 5 * 1000
        //  });
         return Promise.reject(error);
     }
 );
 
 export default request;