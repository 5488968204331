import request from "@/utils/request";
import { CancelToken } from "axios";
import store from "../store";
export function shopLi(query) {
  return request({
    url: "/device/data/shop/drop/down",
    method: "get",
    params: query,
  });
}

// 食堂食安概览
export function shopGl(query) {
  return request({
    url: "/shop/data/shop/overview",
    method: "get",
    params: query,
  });
}
// 营业许可证风险
export function licenseAlarmList(query) {
  return request({
    url: "/shop/data/license/alarm",
    method: "get",
    params: query,
  });
}
// sop--加工监管
export function workSupervise(query) {
  return request({
    url: "/shop/data/work/supervise",
    method: "get",
    params: query,
  });
}
// 食堂服务员工健康分析
export function shopStaffHealth(query) {
  return request({
    url: "/shop/data/staff/statistic",
    method: "get",
    params: query,
  });
}
export function morningCheckTable(query) {
  return request({
    url: "/shop/data/morning/check/record",
    method: "get",
    params: query,
  });
}

export function morningCheckInfo(query) {
  return request({
    url: "/shop/data/morning/check",
    method: "get",
    params: query,
  });
}

// 食堂日管控
export function dayInfo(query) {
  return request({
    url: "/shop/data/info/public",
    method: "get",
    params: query,
  });
}
export function staffControl(query) {
  return request({
    url: "/shop/data/staff/control",
    method: "get",
    params: query,
  });
}
export function workEnv(query) {
  return request({
    url: "/shop/data/work/environment",
    method: "get",
    params: query,
  });
}
export function workPro(query) {
  return request({
    url: "/shop/data/work/process",
    method: "get",
    params: query,
  });
}
export function material(query) {
  return request({
    url: "/shop/data/material/control",
    method: "get",
    params: query,
  });
}
export function deviceControl(query) {
  return request({
    url: "/shop/data/maintenance/control",
    method: "get",
    params: query,
  });
}
export function hygiene(query) {
  return request({
    url: "/shop/data/hygiene/control",
    method: "get",
    params: query,
  });
}

// 留样监管
export function lyRate(query) {
  return request({
    url: "/shop/data/liuyang/schedule",
    method: "get",
    params: query,
  });
}
export function lyRecordList(query) {
  return request({
    url: "/shop/data/liuyang/records",
    method: "get",
    params: query,
  });
}

// 菜品公示
export function mealShow(query) {
  return request({
    url: "/shop/data/food/public",
    method: "get",
    params: query,
  });
}

// 供应商信息
export function supplier(query) {
  return request({
    url: "/shop/data/supplier/list",
    method: "get",
    params: query,
  });
}

// 留样照片
export function lyPic(query) {
  return request({
    url: "/shop/data/liuyang/picture",
    method: "get",
    params: query,
  });
}
// 本学期巡检得分
export function classScore(query) {
  return request({
    url: "/shop/data/check/get/score",
    method: "get",
    params: query,
  });
}

// 整改提升分析头部
export function zgtsData(query) {
  return request({
    url: "/digital/inspection/data/month/check/statistic/top",
    method: "get",
    params: query,
  });
}

// 整改提升分析头部
export function FdData(query) {
  return request({
    url: "/shop/data/shop/of/stall/food",
    method: "get",
    params: query,
  });
}
// 场景风险
export function sceneAlarmList(query) {
  return request({
    url: "/shop/data/scene/alarm",
    method: "get",
    params: query,
  });
}

// 人脸信息缺失
export function faceAlarmList(query) {
  return request({
    url: "/shop/data/face/risk",
    method: "get",
    params: query,
  });
}
// 销样提醒
export function sampleAlarmList(query) {
  return request({
    url: "/shop/data/sample/alarm",
    method: "get",
    params: query,
  });
}
// 客诉
export function complaintAlarmList(query) {
  return request({
    url: "/shop/data/complaint/alarm",
    method: "get",
    params: query,
  });
}
// 整改
export function rectifyAlarmList(query) {
  return request({
    url: "/shop/data/rectify/alarm",
    method: "get",
    params: query,
  });
}
// 供货商
export function supplierAlarmList(query) {
  return request({
    url: "/shop/data/supplier/hiatus/expire/alarm",
    method: "get",
    params: query,
  });
}
// 供货商
export function supplierAdventAlarmList(query) {
  return request({
    url: "/shop/data/supplier/advent/alarm",
    method: "get",
    params: query,
  });
}
// 健康证更新
export function healthUpdateAlarmList(query) {
  return request({
    url: "/shop/data/health/update/risk",
    method: "get",
    params: query,
  });
}
// 健康证分险
export function healthAlarmList(query) {
  return request({
    url: "/shop/data/health/risk",
    method: "get",
    params: query,
  });
}
// 陌生人
export function strangerAlarmList(query) {
  return request({
    url: "/shop/data/stranger/alarm",
    method: "get",
    params: query,
  });
}
//门禁测温异常
export function accessAlarmList(query) {
  return request({
    url: "/shop/data/access/temp/high/alarm",
    method: "get",
    params: query,
  });
}
//ai行为报警
export function aiAlarmList(query) {
  return request({
    url: "/shop/data/ai/active/alarm",
    method: "get",
    params: query,
  });
}
// 温度过高
export function temperatureAlarmList(query) {
  return request({
    url: "/shop/data/high/temperature/warn/alarm",
    method: "get",
    params: query,
  });
}
// 离人
export function leaveAlarmList(query) {
  return request({
    url: "/shop/data/leave/people/alarm",
    method: "get",
    params: query,
  });
}
// 自检提醒
export function selfAlarmList(query) {
  return request({
    url: "/shop/data/self/alarm",
    method: "get",
    params: query,
  });
}