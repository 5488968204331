// 设备总览大屏
import request from "@/utils/request";
import store from "../store";
import { CancelToken } from "axios";
// 智慧食安布防
export function zhFoodBf(query) {
  return request({
    url: "/device/data/supervise/status",
    method: "get",
    params: query,
  });
}
// 食堂分数详情顶部数据
export function shopScoreDetail(query) {
  return request({
    url: "/head/quarter/data/shop/score",
    method: "get",
    params: query,
  });
}
// 食堂分数详情分页
export function shopScoreDetailPage(query) {
  return request({
    url: "/head/quarter/data/shop/score/info/page",
    method: "get",
    params: query,
  });
}
// 着装行为弹窗
export function dressingPop(query) {
  return request({
    url: "/shop/data/staff/control/dressing/popup",
    method: "get",
    params: query,
  });
}
// 仓库挡鼠板
export function ratPop(query) {
  return request({
    url: "/shop/data/material/control/rat/popup",
    method: "get",
    params: query,
  });
}
// 加工监管弹窗
export function spacePop(query) {
  return request({
    url: "/shop/data/work/supervise/popup",
    method: "get",
    params: query,
  });
}
// 健康证
export function healthPop(query) {
  return request({
    url: "/shop/data/staff/control/health/popup",
    method: "get",
    params: query,
  });
}
// 农残
export function nongPop(query) {
  return request({
    url: "/shop/data/material/control/nongyao/popup",
    method: "get",
    params: query,
  });
}
// 智慧设备头部
export function deviceTop(query) {
  return request({
    url: "/shop/data/maintenance/control/device/popup/top",
    method: "get",
    params: query,
  });
}
// 智能设备数据统计
export function znDevice(query) {
  return request({
    url: "/device/data/statistic",
    method: "get",
    params: query,
  });
}
// 设备布防概览
export function deviceBf(query) {
  return request({
    url: "/device/data/info",
    method: "get",
    params: query,
  });
}

// 监管得分
export function scoreJg(query) {
  return request({
    url: "/device/data/control/score",
    method: "get",
    params: query,
  });
}
// 安全预警中心
export function yjCenter(query) {
  return request({
    url: "/device/data/safe/warning/center",
    method: "get",
    params: query,
  });
}

// 食堂设备监管总览
export function deviceJg(query) {
  return request({
    url: "/device/data/safe/index",
    method: "get",
    params: query,
  });
}

// 智慧食堂物联监管
// 油锅离人监管
export function ygLeace(query) {
  return request({
    url: "/device/data/fire/control",
    method: "get",
    params: query,
    cancelToken:new CancelToken(function executor(c) { 
      store.dispatch('setCancel',c)
  })
  });
}
// 留样监管
export function sample(query) {
  return request({
    url: "/device/data/liuyang/control",
    method: "get",
    params: query,
    cancelToken:new CancelToken(function executor(c) { 
      store.dispatch('setCancel',c)
  })
  });
}
// 主食仓库和副食仓库
export function warehouse(query) {
  return request({
    url: "/device/data/food/warehouse",
    method: "get",
    params: query,
    cancelToken:new CancelToken(function executor(c) { 
      store.dispatch('setCancel',c)
  })
  });
}
export function warehouseFu(query) {
  return request({
    url: "/device/data/food/warehouse",
    method: "get",
    params: query,
    cancelToken:new CancelToken(function executor(c) { 
      store.dispatch('setCancel',c)
  })
  });
}
// 着装规范
export function clothing(query) {
  return request({
    url: "/device/data/ai/control",
    method: "get",
    params: query,
  });
}
// 后厨出入
export function kitchen(query) {
  return request({
    url: "/device/data/kitchen/access",
    method: "get",
    params: query,
    cancelToken:new CancelToken(function executor(c) { 
      store.dispatch('setCancel',c)
  })
  });
}
// 环境消毒
export function disinfect(query) {
  return request({
    url: "/device/data/disinfect/control",
    method: "get",
    params: query,
    cancelToken:new CancelToken(function executor(c) { 
      store.dispatch('setCancel',c)
  })
  });
}
// 食材农残
export function pesticide(query) {
  return request({
    url: "/device/data/nongyao",
    method: "get",
    params: query,
    cancelToken:new CancelToken(function executor(c) { 
      store.dispatch('setCancel',c)
  })
  });
}
// 获取ai摄像头通道列表-AI实时监控
export function AiList(query) {
  return request({
    url: "/device/data/ai/channel",
    method: "get",
    params: query,
  });
}

// 获取火眼通道
export function AiFireList(query) {
  return request({
    url: "/device/data/ai/fire/channel",
    method: "get",
    params: query,
  });
}
// 留样详情菜品
export function lymealLi(query) {
  return request({
    url: "/shop/data/liuyang/item/list",
    method: "get",
    params: query,
  });
}
// 留样详情图片
export function lyPicLi(query) {
  return request({
    url: "/shop/data/liuyang/item/picture/list",
    method: "get",
    params: query,
  });
}