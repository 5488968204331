<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <!-- <div style="color: #606266; font-size: 13px">
      共 {{ total }} 条
      <el-dropdown style="margin-left: 10px" @command="choose" placement="top">
        <span style="border: 1px solid #eee; color: #606266; font-size: 13px; padding: 5.5px 8px 5.5px 18px; border-radius: 3px" class="el-dropdown-link"> {{ ownPageSize }}条/页<i class="el-icon-arrow-down el-icon--right" style="margin-left: 15px"></i> </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in pageSizes" :command="item" :key="item">{{ item }}条/页</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->
    <el-pagination ref="pag" :background="background" :current-page.sync="currentPage" :page-size.sync="ownPageSize" :layout="layout" :page-sizes="pageSizes" :total="total" v-bind="$attrs" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
import { scrollTo } from "@/utils/scroll-to";

export default {
  name: "Pagination",
  data() {
    return { ownPageSize: 10 };
  },
  props: {
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      },
    },
    layout: {
      type: String,
      default: "prev, pager, next",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
  },
  methods: {
    choose(val) {
      console.log("val", val);
      this.ownPageSize = val;
      this.$emit("update:limit", val);
      this.handleSizeChange(val);
    },
    handleSizeChange(val) {
      this.$emit("pagination", { page: this.currentPage, limit: val });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.ownPageSize });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-container {
  position: relative;
  //   background: #fff;
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
::v-deep .el-pagination {
  position: relative;
}

.el-dropdown-link:hover {
  border: 1px solid #1890ff !important;
}
.pagination-container .el-pagination {
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
}

// .el-table .fixed-width .el-button--mini {
//   color: red;
//   padding-left: 0;
//   padding-right: 0;
//   width: inherit;
// }
/deep/ .el-pager .number {
  width: 0.1875rem;
  height: 0.1875rem;
  line-height: 0.1875rem;
  background: linear-gradient(180deg, rgba(39, 115, 255, 0.17) 0%, rgba(39, 115, 255, 0.2) 100%) !important;
  box-sizing: border-box;
  border: 1px solid rgba(39, 115, 255, 0.4);
  left: 0;
  //   color: #bcd2ed !important;
}

/deep/ .el-icon-more {
  width: 0.1875rem;
  height: 0.1875rem;
  line-height: 0.1875rem;
  border: 1px solid rgba(39, 115, 255, 0.4);
  background: linear-gradient(180deg, rgba(39, 115, 255, 0.17) 0%, rgba(39, 115, 255, 0.2) 100%) !important;
}
/deep/ .el-pager .more::before {
  line-height: 0.1875rem;
  color: #bcd2ed;
}
/deep/ .btn-next {
  width: 0.1875rem;
  height: 0.1875rem;
  line-height: 0.1875rem;
}
/deep/ .el-icon-d-arrow-right {
  width: 0.1875rem;
  height: 0.1875rem;
  line-height: 0.1875rem;
  border: 1px solid rgba(39, 115, 255, 0.4);
  background: linear-gradient(180deg, rgba(39, 115, 255, 0.17) 0%, rgba(39, 115, 255, 0.2) 100%) !important;
}
/deep/.el-icon-d-arrow-left {
  width: 0.1875rem;
  height: 0.1875rem;
  border: 1px solid rgba(39, 115, 255, 0.4);
  line-height: 0.1875rem;
  background: linear-gradient(180deg, rgba(39, 115, 255, 0.17) 0%, rgba(39, 115, 255, 0.2) 100%) !important;
}
/deep/ .btn-prev {
  color: #fff !important;

  width: 0.1875rem;
  height: 0.1875rem;
  line-height: 0.1875rem;
  background: linear-gradient(180deg, rgba(39, 115, 255, 0.17) 0%, rgba(39, 115, 255, 0.2) 100%) !important;
}
/deep/ .btn-next {
  color: #fff !important;
  width: 0.1875rem;
  height: 0.1875rem;
  line-height: 0.1875rem;
  background: linear-gradient(180deg, rgba(39, 115, 255, 0.17) 0%, rgba(39, 115, 255, 0.2) 100%) !important;
}
/deep/.el-pagination.is-background .el-pager li {
  color: #bcd2ed;
  font-weight: 400;
  font-size: 0.0625rem;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #fff;
  font-weight: 600;
  font-size: 0.0625rem;
  border: 1px solid #2773ff;
  background: linear-gradient(180deg, rgba(39, 115, 255, 0.17) 0%, rgba(39, 115, 255, 0.69) 100%);
}
</style>
