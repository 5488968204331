/**
 * aes加解密工具类
 */

import CryptoJS from 'crypto-js';

export default {
    // 随机生成指定数量的16进制key
    generateAesKey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let aesKey = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            aesKey += library.substring(randomPoz, randomPoz + 1);
        }
        return aesKey;
    },

    // aes加密
    aesEncrypt(content, keyStr) {
        keyStr = keyStr ? keyStr : 'dxe2rxffw5jr4g60';
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var srcs = CryptoJS.enc.Utf8.parse(content);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    },
    // aes解密
    aesDecrypt(decryptContent, keyStr) {
        keyStr = keyStr ? keyStr : 'dxe2rxffw5jr4g60';
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var decrypt = CryptoJS.AES.decrypt(decryptContent, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }

}