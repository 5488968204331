const cancelFun = {
    state: {
        cancelTokens:[]
    },
    mutations: {
        setCancel: (state, cancelToken) => {
            state.cancelTokens.push(cancelToken)
        },
        clearCancel: (state, cancelToken) => {
            state.cancelTokens = []
        },
    },

    actions: {
        setCancel({ commit }, cancelToken) {
            commit("setCancel", cancelToken);
        },
        clearCancel({ commit }){
            commit("clearCancel");
        },
    },
};

export default cancelFun;
