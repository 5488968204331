<template>
  <div class="video">
    <video class="videoPlay" style="width: 100%; height: 100%;" autoplay controls muted id="videoElement"></video>
  </div>
</template>
<script>
import flvjs from 'flv.js'
export default {
  data() {
    return {
      flvPlayer: null,
      url: 'https://rtmp01open.ys7.com:9188/v3/openlive/K68559976_3_1.flv?expire=1692134651&id=607597998670622720&t=52f46a3b6e3122204180672ce48b1ace61ff6c5fd59fdf6096e2c709ef09dc01&ev=100&supportH265=1'
    }
  },
  mounted() {
    this.$nextTick(() => {
      // url为播放视频的路径,type可以是 'flv',也可以是'mp4'
      this.createVideo(this.url, 'flv')
    })
  },
  beforeDestroy() {
    if (this.flvPlayer) {
      this.destoryVideo(this.flvPlayer)
    }
  },
  methods: {
    createVideo(url, type) {
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById('videoElement')
        this.flvPlayer = flvjs.createPlayer(
          {
            type: type,
            isLive: true,
            hasAudio: false,
            url: url
          },
          {
            enableWorker: false, // 不启用分离线程
            enableStashBuffer: false, // 关闭IO隐藏缓冲区
            reuseRedirectedURL: true, // 重用301/302重定向url，用于随后的请求，如查找、重新连接等。
            autoCleanupSourceBuffer: true, // 自动清除缓存
            fixAudioTimestampGap: false
          }
        )

        this.flvPlayer.attachMediaElement(videoElement)
        // this.flvPlayer.load();

        if (this.url !== '' && this.url !== null) {
          this.flvPlayer.load()
          this.flvPlayer.play()
        }
      }
      this.flvPlayer.on(flvjs.Events.ERROR, (errType, errDetail) => {
        // alert('网络波动,正在尝试连接中...');
        if (this.flvPlayer) {
          this.reloadVideo(this.flvPlayer)
        }
      })
    },
    reloadVideo(flvPlayer) {
      this.destoryVideo(flvPlayer)
      // this.url为视频播放的路径
      this.createVideo(this.url, 'flv')
    },
    destoryVideo(flvPlayer) {
      flvPlayer.pause()
      flvPlayer.unload()
      flvPlayer.detachMediaElement()
      flvPlayer.destroy()
      flvPlayer = null
    }
  }
}
</script>
<style lang='less' scoped></style>
