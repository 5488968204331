<template>
  <div class="footer">
    <div :class="{ safe: true, chooseSafe: current === 1 }" @click="tabClick(1)"></div>
      <div class="foot-split"></div>
    <!-- <div :class="{ device: true, chooseDevice: current === 2 }" @click="tabClick(2)"></div> -->

    <!-- <div :class="{ number: true, chooseNumber: current === 3 }" @click="tabClick(3)"></div> -->

    <div class="number" :class="{ number: true, chooseNumber: current === 3 }" @click="tabClick(3)"></div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      default: 1,
    },
  },
  methods: {
    tabClick(e) {
      if (this.current == e) {
        return;
      }
      // this.current = e;
      if (e == 2) {
        this.$router.push("/device");
      } else if (e == 1) {
        this.$router.push("/safe");
      } else if (e == 3) {
        this.$router.push("/check");
      }
    },
  },
};
</script>

<style lang="scss">
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 0.2135rem;
  display: flex;
  // align-items: center;
  justify-content: center;
  //   background-color: #fff;
}
.safe {
  cursor: pointer;
  width: 1.875rem;
  height: 0.2135rem;
  background-image: url("@/assets/food-control-nochoose.png");
  background-size: 100% 100%;
  position: relative;
  right: -.0625rem;
}
.foot-split{
  width: .1719rem;
  height: .1302rem;
  background-image: url("@/assets/foot-split.png");
  background-size: 100% 100%;
  position: relative;
  top: .0104rem;
}
.chooseSafe {
  cursor: pointer;
  background-image: url("@/assets/food-control-choose.png") !important;
}
.device {
  cursor: pointer;

  width: 0.6667rem;
  margin-left: -0.0833rem;
  height: 0.2135rem;
  background-image: url("@/assets/device-noChoose.png");
  background-size: 100% 100%;
}
.chooseDevice {
  cursor: pointer;
  background-image: url("@/assets/device-choosed.png") !important;
}
.number {
  cursor: pointer;
  width: 1.875rem;
  // margin-left: 0.0208rem;
  height: 0.2135rem;
  background-image: url("@/assets/num-check-nochoose.png");
  background-size: 100% 100%;
  position: relative;
  left: -.0625rem;
}
.chooseNumber {
  cursor: pointer;
  background-image: url("@/assets/num-check-choose.png") !important;
}
.st {
  cursor: pointer;
  width: 2.0313rem;
  height: 0.2135rem;
  margin-left: -.0521rem;
  background-image: url("@/assets/st-noChoose.png");
  background-size: 100% 100%;
}
.chooseSt {
  cursor: pointer;
  background-image: url("@/assets/st-choosed.png") !important;
}
</style>
