<template>
    <div class="chart" :ref="tip"></div>
</template>

<script>
    import * as echarts from "echarts";
    export default {
        data() {
            return {
                widthPix:1
            };
        },
        props:{
            showLegend:{
                default:true
            },
            showTitle:{
                default:true
            },
            tip:{
                default:"pie"
            }
        },
        methods: {
            initChart(series) {
                let myChart = null;
                if (myChart != null && myChart != "" && myChart != undefined) {
                    myChart.dispose();
                }
                var chartDom = this.$refs[this.tip];
                myChart = echarts.init(chartDom);
                var option;

                option = {
                    title:{
                        show:this.showTitle,
                        text:'健康证维护情况',
                        textStyle:{
                            fontSize:this.widthPix * 12,
                            color:'#AEC3DC'
                        }
                    },
                    legend: {
                        show:this.showLegend,
                        bottom: "0%",
                        left: "center",
                        itemWidth: this.widthPix * 4,
                        itemHeight: this.widthPix * 4,
                        borderRadius: this.widthPix * 2,
                        itemGap: this.widthPix * 15,
                        textStyle: {
                            color: "#BCD2ED",
                        },
                    },
                    tooltip: {
                        trigger: "item",
                    },

                    series: series
                };
                myChart.clear()
                option && myChart.setOption(option);
            },
        },
        mounted() {
            this.widthPix = window.screen.width<1920?1: Math.floor(window.screen.width/ 1920)
            this.initChart();
        },
    };
</script>
<style lang="scss" scoped>
    .chart {
        width: 100%;
        height:  100%;
    }
</style>
