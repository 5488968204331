// 数字巡检
import request from "@/utils/request";

// 后勤指挥巡检
export function logisticsCommand(query = {}) {
  return request({
    url: "/digital/inspection/data/rectify/overview",
    method: "get",
    params: query,
  });
}

// 本月食堂巡检进度
export function inspectionProgress(query = {}) {
  return request({
    url: "/digital/inspection/data/month/check/progress",
    method: "get",
    params: query,
  });
}
// 督查和视频巡检头部
export function xjHead(query = {}) {
  return request({
    url: "/digital/inspection/data/month/check/progress/popup/head",
    method: "get",
    params: query,
  });
}
export function xjTable(query = {}) {
  return request({
    url: "/digital/inspection/data/month/check/progress/popup",
    method: "get",
    params: query,
  });
}

// 本月整改分布雷达图
export function rectifyRadar(query = {}) {
  return request({
    url: "/digital/inspection/data/rectify/statistic/pie",
    method: "get",
    params: query,
  });
}
// 本月整改分布 --- 整改统计
export function rectifyStatistic(query = {}) {
  return request({
    url: "/digital/inspection/data/rectify/undo/statistic",
    method: "get",
    params: query,
  });
}
//
export function inspectionAnalyse(query = {}) {
  return request({
    url: "/head/quarter/data/rectify/statistic",
    method: "get",
    params: query,
  });
}
// 本月食堂巡检分析
export function inspectionAnalyse2(query = {}) {
  return request({
    url: "/digital/inspection/data/month/check/statistic",
    method: "get",
    params: query,
  });
}
// 近三月重点提升方向左侧
export function improvementTable(query = {}) {
  return request({
    url: "/digital/inspection/data/rectify/problem/statistic",
    method: "get",
    params: query,
  });
}

// 南丁格尔图
export function improvementNightTing(query = {}) {
  return request({
    url: "/digital/inspection/data/rectify/problem/category/statistic",
    method: "get",
    params: query,
  });
}
// 食堂红榜
export function shopTopRed(query = {}) {
  return request({
    url: "/digital/inspection/data/shop/good/top",
    method: "get",
    params: query,
  });
}
// 食堂黑榜单
export function shopTopBlack(query = {}) {
  return request({
    url: "/digital/inspection/data/shop/bad/top",
    method: "get",
    params: query,
  });
}

// 实时巡查情况
export function checkRecordOnTime(query = {}) {
  return request({
    url: "/digital/inspection/data/check/record",
    method: "get",
    params: query,
  });
}

// 自检自清提交统计下拉
export function rulesList(query = {}) {
  return request({
    url: "/digital/inspection/data/log/rule/list",
    method: "get",
    params: query,
  });
}
// 自检自清提交统计下拉
export function rulesTableList(query = {}) {
  return request({
    url: "/digital/inspection/data/log/rule/statistic/list",
    method: "get",
    params: query,
  });
}
// 食安黑榜详情
export function blackDetail(query = {}) {
  return request({
    url: "/digital/inspection/data/shop/bad/popup",
    method: "get",
    params: query,
  });
}
export function shopTopBlack2(query = {}) {
  return request({
    url: "/digital/inspection/data/shop/bad",
    method: "get",
    params: query,
  });
}