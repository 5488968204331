/**
 * rsa加密
 */
import JSEncrypt from 'jsencrypt/bin/jsencrypt';

// rsa加密
export function rsaEncrypt(content, publicKey) {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey);
    return encryptor.encrypt(content);
}